<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15.798" height="19.481" viewBox="0 0 15.798 19.481">
    <g id="Сгруппировать_350" data-name="Сгруппировать 350" transform="translate(-114.849 -1314.763) rotate(10)">
      <circle id="Эллипс_238" data-name="Эллипс 238" cx="4.087" cy="4.087" r="4.087"
        transform="translate(349.077 1273.494) rotate(16)" fill="#2475dd" />
      <circle id="Эллипс_239" data-name="Эллипс 239" cx="2.5" cy="2.5" r="2.5"
        transform="translate(345.029 1282.75) rotate(16)" fill="#2475dd" />
      <circle id="Эллипс_240" data-name="Эллипс 240" cx="3.262" cy="3.262" r="3.262"
        transform="translate(353.062 1283.716) rotate(16)" fill="#2475dd" />
    </g>
  </svg>
</template>
