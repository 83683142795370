<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="25.348" height="24.3" viewBox="0 0 25.348 24.3">
    <path
      id="event"
      d="M13.524,2l3.561,7.241,7.963,1.168-5.762,5.633L20.646,24l-7.122-3.759L6.4,24l1.36-7.958L2,10.409,9.963,9.241Z"
      transform="translate(-0.85 -0.85)"
      fill="none"
      stroke="#717171"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.3"
      :class="active ? 'svgColor' : ''"
    />
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  setup () {}
}
</script>

<style scoped>
.svgColor {
  stroke: #2473da;
}
</style>
