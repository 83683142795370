<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24.498" height="24" viewBox="0 0 24.498 24">
    <g id="filter" transform="translate(-4 -4.323)">
      <path
        id="Контур_611"
        data-name="Контур 611"
        d="M24.11,7H11"
        transform="translate(2.89 1.323)"
        fill="none"
        stroke="#717171"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        :class="active ? 'svgColor' : ''"
      />
      <path
        id="Контур_615"
        data-name="Контур 615"
        d="M13.58,7H11"
        transform="translate(-6 1.323)"
        fill="none"
        stroke="#717171"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        :class="active ? 'svgColor' : ''"
      />
      <path
        id="Контур_612"
        data-name="Контур 612"
        d="M20.936,17H5"
        transform="translate(0 -0.645)"
        fill="none"
        stroke="#717171"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        :class="active ? 'svgColor' : ''"
      />
      <path
        id="Контур_718"
        data-name="Контур 718"
        d="M12.785,17H5"
        transform="translate(0 7.323)"
        fill="none"
        stroke="#717171"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        :class="active ? 'svgColor' : ''"
      />
      <path
        id="Контур_719"
        data-name="Контур 719"
        d="M11.721,17H5"
        transform="translate(14.137 7.323)"
        fill="none"
        stroke="#717171"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        :class="active ? 'svgColor' : ''"
      />
      <path
        id="Контур_613"
        data-name="Контур 613"
        d="M17,14a3,3,0,1,0,3,3A3,3,0,0,0,17,14Z"
        transform="translate(7.498 -0.676)"
        fill="none"
        stroke="#717171"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        :class="active ? 'svgColor' : ''"
      />
      <path
        id="Контур_717"
        data-name="Контур 717"
        d="M17,14a3,3,0,1,0,3,3A3,3,0,0,0,17,14Z"
        transform="translate(-0.863 7.323)"
        fill="none"
        stroke="#717171"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        :class="active ? 'svgColor' : ''"
      />
      <path
        id="Контур_614"
        data-name="Контур 614"
        d="M7,4a3,3,0,1,0,3,3A3,3,0,0,0,7,4Z"
        transform="translate(3.807 1.323)"
        fill="none"
        stroke="#717171"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        :class="active ? 'svgColor' : ''"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  setup () {}
}
</script>

<style scoped>
.svgColor {
  stroke: #2473da;
}
</style>
