<template>
  <section>
    <svg xmlns="http://www.w3.org/2000/svg" width="219" height="56.767" viewBox="0 0 219 56.767">
      <g id="Сгруппировать_1" data-name="Сгруппировать 1" transform="translate(-0.512 -0.243)">
        <path
          id="Контур_1"
          data-name="Контур 1"
          d="M5.877,41.771c0,6.125,2.956,9.188,8.924,9.188a12.224,12.224,0,0,0,6.515-1.477v-6.4a8.8,8.8,0,0,1-4,.985c-2.628,0-3.723-1.148-3.723-3.828V29.412H21.37V22.959H13.6V15.466H5.877v7.492H.512v6.453H5.877Z"
          transform="translate(0 -11.723)"
        />
        <path
          id="Контур_2"
          data-name="Контур 2"
          d="M127.147,75.343V48.054h-7.719v27.29Z"
          transform="translate(-91.576 -36.818)"
        />
        <path
          id="Контур_3"
          data-name="Контур 3"
          d="M194.5,73.673a18.028,18.028,0,0,0,8.65-2.023V65.087a14.271,14.271,0,0,1-7.391,1.86,8.914,8.914,0,0,1-6.022-2.133,7.418,7.418,0,0,1-.055-10.992,8.958,8.958,0,0,1,6.077-2.133,16.539,16.539,0,0,1,6.953,1.641V46.766a17.826,17.826,0,0,0-8.1-1.8A15.347,15.347,0,0,0,184.044,48.9a13.14,13.14,0,0,0-4.38,10.336,13.65,13.65,0,0,0,4.38,10.5A15.077,15.077,0,0,0,194.5,73.673Z"
          transform="translate(-137.963 -34.437)"
        />
        <path
          id="Контур_4"
          data-name="Контур 4"
          d="M318.019,38.525V26.767L328.8,38.525h9.964L325.027,23.54l11.88-12.3h-9.471l-9.416,9.953V.243H310.3V38.525Z"
          transform="translate(-238.563 0)"
        />
        <path
          id="Контур_5"
          data-name="Контур 5"
          d="M451.547,73.673c4.489,0,7.938-.93,10.4-2.789V64.7a17.469,17.469,0,0,1-8.759,2.406c-4.818,0-8.322-1.969-9.143-5.688h19.6V59.4a15.306,15.306,0,0,0-3.559-10.227,11.732,11.732,0,0,0-9.471-4.211,13.5,13.5,0,0,0-10.292,4.156,14.109,14.109,0,0,0-3.942,10.172,13.451,13.451,0,0,0,4.38,10.446A15.512,15.512,0,0,0,451.547,73.673Zm-7.5-17.172a5.959,5.959,0,0,1,6.241-5.469,5.553,5.553,0,0,1,5.749,5.469Z"
          transform="translate(-335.657 -34.437)"
        />
        <path
          id="Контур_6"
          data-name="Контур 6"
          d="M573.959,41.771c0,6.125,2.956,9.188,8.924,9.188a12.224,12.224,0,0,0,6.515-1.477v-6.4a8.8,8.8,0,0,1-4,.985c-2.628,0-3.723-1.148-3.723-3.828V29.412h7.774V22.959h-7.774V15.466h-7.719v7.492h-5.365v6.453h5.365Z"
          transform="translate(-437.471 -11.723)"
        />
        <path
          id="Контур_7"
          data-name="Контур 7"
          d="M127.147.243V8.785h-7.719V.243Z"
          transform="translate(-91.576 0)"
        />
        <path
          id="Контур_8"
          data-name="Контур 8"
          d="M849.619,73.671c4.489,0,7.938-.93,10.4-2.789V64.7a17.47,17.47,0,0,1-8.759,2.406c-4.818,0-8.322-1.969-9.143-5.688h19.6V59.4a15.305,15.305,0,0,0-3.559-10.227,11.732,11.732,0,0,0-9.471-4.211A13.5,13.5,0,0,0,838.4,49.116a14.108,14.108,0,0,0-3.942,10.172,13.452,13.452,0,0,0,4.38,10.446A15.512,15.512,0,0,0,849.619,73.671Zm-7.5-17.172a5.959,5.959,0,0,1,6.241-5.469,5.553,5.553,0,0,1,5.749,5.469Z"
          transform="translate(-642.206 -34.436)"
          fill="#0575dd"
        />
        <path
          id="Контур_9"
          data-name="Контур 9"
          d="M689.064,48.052h-6.9v27.29h7.719v-14.6a14.285,14.285,0,0,0,8.09,2.44,14.129,14.129,0,0,0,8.936-3.073V75.342h7.719V54.515h0V48.052h-7.745a8.936,8.936,0,0,1-17.82,0Z"
          transform="translate(-524.93 -36.817)"
          fill="#0575dd"
          fill-rule="evenodd"
        />
        <path
          id="Контур_10"
          data-name="Контур 10"
          d="M34.238,201.322a4.282,4.282,0,0,1-2.094-.467,3.2,3.2,0,0,1-1.266-1.352,5.033,5.033,0,0,1-.455-2.044q-.016-.483-.016-.966t.016-.966a10.156,10.156,0,0,1,.244-2.43,4.115,4.115,0,0,1,.731-1.577,3.975,3.975,0,0,1,1.266-1.03,9.119,9.119,0,0,1,1.8-.692q1.039-.306,2.37-.644a.36.36,0,0,1,.276.048.287.287,0,0,1,.162.225l.13.66a.34.34,0,0,1-.26.4q-1.218.306-2.127.531a7.553,7.553,0,0,0-1.526.531,2.725,2.725,0,0,0-.99.756,2.781,2.781,0,0,0-.519,1.239,9.911,9.911,0,0,0-.081,1.98l-.357-.145a2.03,2.03,0,0,1,.5-1.078,3.3,3.3,0,0,1,1.088-.8,3.2,3.2,0,0,1,1.38-.306,3.548,3.548,0,0,1,1.883.467,3.064,3.064,0,0,1,1.185,1.271,4.375,4.375,0,0,1,.471,1.851,6.915,6.915,0,0,1,0,.95,4.34,4.34,0,0,1-.487,1.867,3.2,3.2,0,0,1-1.266,1.271A4.274,4.274,0,0,1,34.238,201.322Zm0-1.191a2.141,2.141,0,0,0,1.623-.628,2.664,2.664,0,0,0,.633-1.835q.016-.161.016-.37t-.016-.435a2.688,2.688,0,0,0-.633-1.851,1.932,1.932,0,0,0-1.477-.628,2.363,2.363,0,0,0-1.688.628,2.574,2.574,0,0,0-.714,1.851q-.016.226-.016.435t.016.37a2.722,2.722,0,0,0,.649,1.835A2.121,2.121,0,0,0,34.238,200.131Z"
          transform="translate(-23.022 -144.312)"
          fill="#0575dd"
        />
        <path
          id="Контур_11"
          data-name="Контур 11"
          d="M79.277,213.374a.39.39,0,0,1-.26-.1.363.363,0,0,1-.1-.241v-7.661a.347.347,0,0,1,.1-.258.378.378,0,0,1,.276-.113h.779a.327.327,0,0,1,.26.113.347.347,0,0,1,.1.258V212.2l-.422-.435,4.919-6.518a.838.838,0,0,1,.179-.161.37.37,0,0,1,.243-.08H86.1a.313.313,0,0,1,.227.1.289.289,0,0,1,.114.225V213a.372.372,0,0,1-.114.274.353.353,0,0,1-.26.1H85.3a.375.375,0,0,1-.276-.1.4.4,0,0,1-.1-.274v-6.422l.455-.016-4.951,6.567a1.156,1.156,0,0,1-.162.161.4.4,0,0,1-.26.08Z"
          transform="translate(-60.381 -156.525)"
          fill="#0575dd"
        />
        <path
          id="Контур_12"
          data-name="Контур 12"
          d="M125.232,213.374a.378.378,0,0,1-.276-.113.371.371,0,0,1-.114-.274v-.6a.316.316,0,0,1,.341-.354,1.071,1.071,0,0,0,.844-.467,3.621,3.621,0,0,0,.519-1.465,16.008,16.008,0,0,0,.179-2.672v-2.06a.346.346,0,0,1,.1-.258.377.377,0,0,1,.276-.113h5.162a.327.327,0,0,1,.26.113.321.321,0,0,1,.114.258V213a.371.371,0,0,1-.114.274.353.353,0,0,1-.26.1h-.779a.353.353,0,0,1-.26-.1.4.4,0,0,1-.1-.274v-6.711H128.2v1.32a17,17,0,0,1-.179,2.656,5.728,5.728,0,0,1-.552,1.787,2.388,2.388,0,0,1-.925,1A2.566,2.566,0,0,1,125.232,213.374Z"
          transform="translate(-95.745 -156.525)"
          fill="#0575dd"
        />
        <path
          id="Контур_13"
          data-name="Контур 13"
          d="M177.617,213a3.552,3.552,0,0,1-2.662-1.014,4.225,4.225,0,0,1-1.087-2.8q-.017-.209-.016-.531,0-.338.016-.547a4.622,4.622,0,0,1,.536-2,3.334,3.334,0,0,1,1.283-1.336,4.309,4.309,0,0,1,3.994.048,3.277,3.277,0,0,1,1.282,1.465,5.254,5.254,0,0,1,.438,2.221v.274a.339.339,0,0,1-.114.274.353.353,0,0,1-.26.1h-5.617v.145a3.418,3.418,0,0,0,.292,1.239,2.309,2.309,0,0,0,.747.917,1.934,1.934,0,0,0,1.169.354,2.357,2.357,0,0,0,.974-.177,2.662,2.662,0,0,0,.633-.386,2.717,2.717,0,0,0,.325-.338.7.7,0,0,1,.227-.241.528.528,0,0,1,.26-.048h.779a.352.352,0,0,1,.26.1.243.243,0,0,1,.1.241,1.285,1.285,0,0,1-.26.6,3.214,3.214,0,0,1-.7.676,4.086,4.086,0,0,1-1.1.563A4.877,4.877,0,0,1,177.617,213Zm-2.208-4.957h4.448v-.048a2.891,2.891,0,0,0-.276-1.288,2.04,2.04,0,0,0-.763-.885,2.3,2.3,0,0,0-2.4,0,2.078,2.078,0,0,0-.747.885,3.044,3.044,0,0,0-.26,1.288Z"
          transform="translate(-133.486 -155.986)"
          fill="#0575dd"
        />
        <path
          id="Контур_14"
          data-name="Контур 14"
          d="M220.747,213.374a.411.411,0,0,1-.276-.1.4.4,0,0,1-.1-.274v-6.711h-2.825a.411.411,0,0,1-.276-.1.4.4,0,0,1-.1-.274v-.547a.346.346,0,0,1,.1-.258.377.377,0,0,1,.276-.113h7.143a.327.327,0,0,1,.26.113.321.321,0,0,1,.114.258v.547a.37.37,0,0,1-.114.274.353.353,0,0,1-.26.1h-2.825V213a.4.4,0,0,1-.1.274.376.376,0,0,1-.276.1Z"
          transform="translate(-166.849 -156.525)"
          fill="#0575dd"
        />
        <path
          id="Контур_15"
          data-name="Контур 15"
          d="M264.342,213.374a.412.412,0,0,1-.276-.1.4.4,0,0,1-.1-.274v-7.629a.347.347,0,0,1,.1-.258.378.378,0,0,1,.276-.113h.779a.326.326,0,0,1,.26.113.321.321,0,0,1,.114.258v2.366h1.688a3.282,3.282,0,0,1,2.419.772,2.848,2.848,0,0,1,.763,2.092,2.52,2.52,0,0,1-.844,2.092,4,4,0,0,1-2.533.676Zm1.137-1.207h1.559a2.444,2.444,0,0,0,1.445-.338,1.463,1.463,0,0,0,.455-1.223,1.6,1.6,0,0,0-.438-1.239,2.08,2.08,0,0,0-1.461-.418h-1.559Zm6.413,1.207a.411.411,0,0,1-.276-.1.4.4,0,0,1-.1-.274v-7.629a.346.346,0,0,1,.1-.258.377.377,0,0,1,.276-.113h.779a.327.327,0,0,1,.26.113.322.322,0,0,1,.114.258V213a.372.372,0,0,1-.114.274.353.353,0,0,1-.26.1Z"
          transform="translate(-202.884 -156.525)"
          fill="#0575dd"
        />
        <path
          id="Контур_16"
          data-name="Контур 16"
          d="M340.491,203.671a.313.313,0,0,1-.227-.1.307.307,0,0,1-.1-.225,1.073,1.073,0,0,1,.016-.177l4.026-10.413a.646.646,0,0,1,.179-.258.515.515,0,0,1,.341-.1h1.1a.487.487,0,0,1,.325.1.584.584,0,0,1,.195.258l4.01,10.413a.56.56,0,0,1,.032.177.308.308,0,0,1-.1.225.313.313,0,0,1-.227.1h-.828a.4.4,0,0,1-.292-.1.685.685,0,0,1-.114-.177l-.893-2.286h-5.325l-.893,2.286a.374.374,0,0,1-.114.177.4.4,0,0,1-.292.1Zm2.6-3.943h4.383l-2.191-5.713Z"
          transform="translate(-261.563 -146.822)"
          fill="#0575dd"
        />
        <path
          id="Контур_17"
          data-name="Контур 17"
          d="M400.338,201.322a4.282,4.282,0,0,1-2.094-.467,3.2,3.2,0,0,1-1.266-1.352,5.032,5.032,0,0,1-.455-2.044q-.016-.483-.016-.966t.016-.966a10.155,10.155,0,0,1,.243-2.43,4.116,4.116,0,0,1,.73-1.577,3.977,3.977,0,0,1,1.266-1.03,9.121,9.121,0,0,1,1.8-.692q1.039-.306,2.37-.644a.361.361,0,0,1,.276.048.287.287,0,0,1,.162.225l.13.66a.34.34,0,0,1-.26.4q-1.217.306-2.127.531a7.552,7.552,0,0,0-1.526.531,2.723,2.723,0,0,0-.99.756,2.781,2.781,0,0,0-.52,1.239,9.925,9.925,0,0,0-.081,1.98l-.357-.145a2.029,2.029,0,0,1,.5-1.078,3.3,3.3,0,0,1,1.087-.8,3.2,3.2,0,0,1,1.38-.306,3.548,3.548,0,0,1,1.883.467,3.064,3.064,0,0,1,1.185,1.271,4.378,4.378,0,0,1,.471,1.851,6.952,6.952,0,0,1,0,.95,4.341,4.341,0,0,1-.487,1.867,3.2,3.2,0,0,1-1.266,1.271A4.272,4.272,0,0,1,400.338,201.322Zm0-1.191a2.142,2.142,0,0,0,1.623-.628,2.664,2.664,0,0,0,.633-1.835q.016-.161.016-.37t-.016-.435a2.687,2.687,0,0,0-.633-1.851,1.932,1.932,0,0,0-1.477-.628,2.363,2.363,0,0,0-1.688.628,2.574,2.574,0,0,0-.714,1.851q-.016.226-.016.435t.016.37a2.722,2.722,0,0,0,.65,1.835A2.12,2.12,0,0,0,400.338,200.131Z"
          transform="translate(-304.95 -144.312)"
          fill="#0575dd"
        />
        <path
          id="Контур_18"
          data-name="Контур 18"
          d="M442.183,213.374a.37.37,0,0,1-.243-.1.326.326,0,0,1-.1-.241.471.471,0,0,1,.016-.113.966.966,0,0,1,.1-.177l2.825-3.654-2.646-3.46a1.583,1.583,0,0,1-.1-.161.528.528,0,0,1-.016-.129.326.326,0,0,1,.1-.241.332.332,0,0,1,.243-.1h.828a.321.321,0,0,1,.26.1,1.163,1.163,0,0,1,.162.161l2.159,2.784,2.159-2.768q.065-.08.146-.177a.376.376,0,0,1,.276-.1h.8a.332.332,0,0,1,.243.1.327.327,0,0,1,.1.241.283.283,0,0,1-.033.129.546.546,0,0,1-.081.161l-2.679,3.492,2.825,3.621a.552.552,0,0,1,.081.161.283.283,0,0,1,.032.129.326.326,0,0,1-.1.241.331.331,0,0,1-.243.1h-.86a.4.4,0,0,1-.26-.08,1.158,1.158,0,0,1-.162-.161l-2.289-2.945-2.289,2.945-.162.161a.358.358,0,0,1-.26.08Z"
          transform="translate(-339.862 -156.525)"
          fill="#0575dd"
        />
        <path
          id="Контур_19"
          data-name="Контур 19"
          d="M489.78,213a3.349,3.349,0,0,1-1.477-.322,2.855,2.855,0,0,1-1.072-.869,2.03,2.03,0,0,1-.406-1.239,2.079,2.079,0,0,1,.909-1.771,5.25,5.25,0,0,1,2.37-.869l2.419-.338v-.467a1.59,1.59,0,0,0-.455-1.207,2,2,0,0,0-1.445-.435,2.175,2.175,0,0,0-1.185.29,1.572,1.572,0,0,0-.617.74.345.345,0,0,1-.341.241h-.73a.375.375,0,0,1-.276-.1.428.428,0,0,1-.081-.258,1.388,1.388,0,0,1,.179-.6,2.588,2.588,0,0,1,.584-.692,3.229,3.229,0,0,1,.99-.563,4.105,4.105,0,0,1,1.494-.241,4.57,4.57,0,0,1,1.64.258,2.5,2.5,0,0,1,1.039.66,2.416,2.416,0,0,1,.552.95,3.363,3.363,0,0,1,.178,1.078v5.215a.371.371,0,0,1-.114.274.352.352,0,0,1-.26.1h-.747a.375.375,0,0,1-.276-.1.4.4,0,0,1-.1-.274v-.692a3.154,3.154,0,0,1-.568.579,3.307,3.307,0,0,1-.893.467A4.235,4.235,0,0,1,489.78,213Zm.341-1.207a2.7,2.7,0,0,0,1.218-.274,2.013,2.013,0,0,0,.86-.885,3.1,3.1,0,0,0,.325-1.5v-.451l-1.883.274a4.279,4.279,0,0,0-1.737.547,1.1,1.1,0,0,0-.584.95,1.129,1.129,0,0,0,.26.756,1.726,1.726,0,0,0,.682.435A2.633,2.633,0,0,0,490.121,211.789Z"
          transform="translate(-374.502 -155.986)"
          fill="#0575dd"
        />
        <path
          id="Контур_20"
          data-name="Контур 20"
          d="M536,213a5.1,5.1,0,0,1-1.623-.225,3.386,3.386,0,0,1-1.055-.6,2.523,2.523,0,0,1-.568-.756,2.1,2.1,0,0,1-.195-.74.276.276,0,0,1,.1-.225.313.313,0,0,1,.227-.1h.763a.369.369,0,0,1,.244.08.4.4,0,0,1,.146.241,1.317,1.317,0,0,0,.747.853,3.772,3.772,0,0,0,2.305.113,1.411,1.411,0,0,0,.682-.451,1.129,1.129,0,0,0,.26-.756,1.25,1.25,0,0,0-.39-1,1.7,1.7,0,0,0-1.169-.338h-.893a.353.353,0,0,1-.26-.1.346.346,0,0,1-.1-.258v-.435a.358.358,0,0,1,.357-.354h.8a1.731,1.731,0,0,0,1.023-.258.943.943,0,0,0,.357-.821,1.354,1.354,0,0,0-.162-.644,1.2,1.2,0,0,0-.552-.5,2.145,2.145,0,0,0-1.039-.209,2.118,2.118,0,0,0-1.331.322,1.711,1.711,0,0,0-.536.917.472.472,0,0,1-.162.241.386.386,0,0,1-.243.064H533a.369.369,0,0,1-.244-.08.276.276,0,0,1-.1-.225,2.42,2.42,0,0,1,.211-.917,2.5,2.5,0,0,1,.617-.789,2.982,2.982,0,0,1,1.039-.547A4.719,4.719,0,0,1,536,204.3a4.128,4.128,0,0,1,1.916.37,2.275,2.275,0,0,1,1.006.917,2.1,2.1,0,0,1,.309,1.062,2.312,2.312,0,0,1-.211,1.046,1.582,1.582,0,0,1-.779.708,1.745,1.745,0,0,1,1.007.821,2.941,2.941,0,0,1,.292,1.272,1.985,1.985,0,0,1-.455,1.384,2.849,2.849,0,0,1-1.25.837A5.635,5.635,0,0,1,536,213Z"
          transform="translate(-409.724 -155.986)"
          fill="#0575dd"
        />
        <path
          id="Контур_21"
          data-name="Контур 21"
          d="M577.6,213.374a.39.39,0,0,1-.26-.1.363.363,0,0,1-.1-.241v-7.661a.346.346,0,0,1,.1-.258.378.378,0,0,1,.276-.113h.779a.327.327,0,0,1,.26.113.346.346,0,0,1,.1.258V212.2l-.422-.435,4.919-6.518a.836.836,0,0,1,.178-.161.37.37,0,0,1,.244-.08h.747a.313.313,0,0,1,.227.1.288.288,0,0,1,.114.225V213a.371.371,0,0,1-.114.274.353.353,0,0,1-.26.1h-.763a.376.376,0,0,1-.276-.1.4.4,0,0,1-.1-.274v-6.422l.455-.016-4.951,6.567a1.156,1.156,0,0,1-.162.161.4.4,0,0,1-.26.08Z"
          transform="translate(-444.129 -156.525)"
          fill="#0575dd"
        />
        <path
          id="Контур_22"
          data-name="Контур 22"
          d="M626.979,213.374a.39.39,0,0,1-.26-.1.363.363,0,0,1-.1-.241v-7.661a.346.346,0,0,1,.1-.258.378.378,0,0,1,.276-.113h.779a.326.326,0,0,1,.26.113.347.347,0,0,1,.1.258V212.2l-.422-.435,4.919-6.518a.842.842,0,0,1,.179-.161.37.37,0,0,1,.244-.08h.747a.314.314,0,0,1,.227.1.289.289,0,0,1,.114.225V213a.372.372,0,0,1-.114.274.353.353,0,0,1-.26.1H633a.376.376,0,0,1-.276-.1.4.4,0,0,1-.1-.274v-6.422l.455-.016-4.951,6.567a1.166,1.166,0,0,1-.162.161.4.4,0,0,1-.26.08Z"
          transform="translate(-482.158 -156.525)"
          fill="#0575dd"
        />
        <path
          id="Контур_23"
          data-name="Контур 23"
          d="M704.375,218.322a.329.329,0,0,0-.134.029.323.323,0,0,0-.111.081.438.438,0,1,1-.653-.583,1.2,1.2,0,0,1,1.8,0,.438.438,0,0,1-.653.583.328.328,0,0,0-.245-.11Z"
          transform="translate(-541.241 -166.106)"
          fill="#ff2c3d"
          fill-rule="evenodd"
        />
        <path
          id="Контур_24"
          data-name="Контур 24"
          d="M692.517,218.322a.329.329,0,0,0-.245.11.438.438,0,0,1-.653-.583,1.2,1.2,0,0,1,1.8,0,.438.438,0,0,1-.653.583.328.328,0,0,0-.245-.11Z"
          transform="translate(-531.766 -166.106)"
          fill="#ff2c3d"
          fill-rule="evenodd"
        />
        <path
          id="Контур_25"
          data-name="Контур 25"
          d="M696.089,226.852a.406.406,0,0,1,.563-.114,1.013,1.013,0,0,0,1.121,0,.406.406,0,1,1,.45.677,1.825,1.825,0,0,1-2.02,0A.406.406,0,0,1,696.089,226.852Z"
          transform="translate(-535.27 -172.918)"
          fill="#ff2c3d"
          fill-rule="evenodd"
        />
        <path
          id="Контур_26"
          data-name="Контур 26"
          d="M687.015,204.381a7.219,7.219,0,0,0,5.458,0,.819.819,0,0,1,1.122.766v0l-.519-.006h.519v2.109a6.621,6.621,0,0,1-1.068,3.722,3.179,3.179,0,0,1-5.568,0,6.623,6.623,0,0,1-1.067-3.722v-2.1l.519-.006h-.519v0m.735.2,0,0Zm.3.111a8.169,8.169,0,0,0,5.626,0v1.8a5.591,5.591,0,0,1-.885,3.133,2.154,2.154,0,0,1-3.857,0,5.592,5.592,0,0,1-.885-3.133Zm5.93-.111,0,0Z"
          transform="translate(-527.801 -156)"
          fill="#ff2c3d"
          fill-rule="evenodd"
        />
        <path
          id="Контур_27"
          data-name="Контур 27"
          d="M763.138,222.44a.541.541,0,1,0,.541.541A.541.541,0,0,0,763.138,222.44Zm-1.58.541a1.58,1.58,0,1,1,1.58,1.58A1.579,1.579,0,0,1,761.558,222.981Z"
          transform="translate(-586.07 -169.152)"
          fill="#ff7a00"
          fill-rule="evenodd"
        />
        <path
          id="Контур_28"
          data-name="Контур 28"
          d="M742.057,227.71a.541.541,0,1,0,.541.541A.541.541,0,0,0,742.057,227.71Zm-1.58.541a1.58,1.58,0,1,1,1.58,1.58A1.58,1.58,0,0,1,740.477,228.251Z"
          transform="translate(-569.836 -173.21)"
          fill="#ff7a00"
          fill-rule="evenodd"
        />
        <path
          id="Контур_29"
          data-name="Контур 29"
          d="M755.569,211.253a.519.519,0,0,1-.378.63l-4.847,1.212a.519.519,0,1,1-.252-1.007l4.847-1.212A.52.52,0,0,1,755.569,211.253Z"
          transform="translate(-576.938 -161.034)"
          fill="#ff7a00"
          fill-rule="evenodd"
        />
        <path
          id="Контур_30"
          data-name="Контур 30"
          d="M755.386,203.064a.519.519,0,0,1,.2.409v5.3a.519.519,0,0,1-1.038,0v-4.636l-3.808.952v4.9a.519.519,0,1,1-1.038,0v-5.3a.519.519,0,0,1,.393-.5l4.847-1.212A.519.519,0,0,1,755.386,203.064Z"
          transform="translate(-576.938 -154.946)"
          fill="#ff7a00"
          fill-rule="evenodd"
        />
        <path
          id="Контур_31"
          data-name="Контур 31"
          d="M797.2,207.566h4.847a1.731,1.731,0,0,1,1.731,1.731v3.635a.822.822,0,0,1-.822.822h-4.847a1.731,1.731,0,0,1-1.731-1.731v-3.635a.822.822,0,0,1,.822-.822Zm.216,1.039h4.631a.693.693,0,0,1,.693.693v3.419h-4.63a.693.693,0,0,1-.693-.693Z"
          transform="translate(-612.886 -158.498)"
          fill="#ffd12c"
          fill-rule="evenodd"
        />
        <path
          id="Контур_32"
          data-name="Контур 32"
          d="M826.949,210.93a.52.52,0,0,1,.259.449v3.635a.519.519,0,0,1-.777.451l-2.12-1.212a.519.519,0,1,1,.515-.9l1.344.768v-1.845l-1.344.768a.519.519,0,1,1-.515-.9l2.12-1.212A.519.519,0,0,1,826.949,210.93Z"
          transform="translate(-634.193 -161.034)"
          fill="#ffd12c"
          fill-rule="evenodd"
        />
        <path
          id="Контур_33"
          data-name="Контур 33"
          d="M860.429,205.831a.823.823,0,0,1,.582-.241h4.847a.822.822,0,0,1,.822.822v2.12h0a3.246,3.246,0,1,1-6.491-.034v-2.086A.822.822,0,0,1,860.429,205.831Zm.8.8v1.87a2.231,2.231,0,0,0,2.192,2.241m-2.192-4.111h4.414v1.9a2.207,2.207,0,0,1-2.222,2.207"
          transform="translate(-662.023 -156.976)"
          fill="#3ec429"
          fill-rule="evenodd"
        />
        <path
          id="Контур_34"
          data-name="Контур 34"
          d="M866.775,235.1a.52.52,0,0,1,.519-.519h2.424a.519.519,0,0,1,0,1.039h-2.424A.52.52,0,0,1,866.775,235.1Z"
          transform="translate(-667.096 -179.298)"
          fill="#3ec429"
          fill-rule="evenodd"
        />
        <path
          id="Контур_35"
          data-name="Контур 35"
          d="M872.565,227.988a.519.519,0,0,1,.519.519v1.515a.519.519,0,1,1-1.038,0v-1.515A.519.519,0,0,1,872.565,227.988Z"
          transform="translate(-671.155 -174.224)"
          fill="#3ec429"
          fill-rule="evenodd"
        />
        <path
          id="Контур_36"
          data-name="Контур 36"
          d="M883.675,210.061a.519.519,0,0,1,.519-.519h1.212a.822.822,0,0,1,.822.822v.606A1.731,1.731,0,0,1,884.5,212.7h-.371a.519.519,0,1,1,0-1.039h.371a.692.692,0,0,0,.693-.692v-.389h-1A.519.519,0,0,1,883.675,210.061Z"
          transform="translate(-680.058 -160.019)"
          fill="#3ec429"
          fill-rule="evenodd"
        />
        <path
          id="Контур_37"
          data-name="Контур 37"
          d="M854.622,210.581h1a.519.519,0,1,0,0-1.039h-1.212a.823.823,0,0,0-.822.822v.606a1.731,1.731,0,0,0,1.731,1.731h.383a.519.519,0,0,0,0-1.039h-.383a.692.692,0,0,1-.692-.692Z"
          transform="translate(-656.937 -160.019)"
          fill="#3ec429"
          fill-rule="evenodd"
        />
        <path
          id="Контур_38"
          data-name="Контур 38"
          d="M923.35,209.94a3.865,3.865,0,1,0-5.057,2.782.962.962,0,0,0,1.289-.91v-.157a.953.953,0,0,1,.072-.37.962.962,0,0,1,.894-.6h1.86a.967.967,0,0,0,.942-.749Z"
          transform="translate(-705.246 -156.686)"
          fill="none"
          stroke="#1797df"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          id="Контур_39"
          data-name="Контур 39"
          d="M930,211.393a.454.454,0,1,0-.455-.454A.454.454,0,0,0,930,211.393Z"
          transform="translate(-715.436 -160.745)"
          fill="#1797df"
        />
        <path
          id="Контур_40"
          data-name="Контур 40"
          d="M922.591,215.675a.454.454,0,1,0-.454-.454A.454.454,0,0,0,922.591,215.675Z"
          transform="translate(-709.729 -164.042)"
          fill="#1797df"
        />
        <path
          id="Контур_41"
          data-name="Контур 41"
          d="M922.591,224.239a.454.454,0,1,0-.454-.455A.455.455,0,0,0,922.591,224.239Z"
          transform="translate(-709.729 -170.637)"
          fill="#1797df"
        />
        <path
          id="Контур_42"
          data-name="Контур 42"
          d="M937.414,215.675a.454.454,0,1,0-.454-.454A.454.454,0,0,0,937.414,215.675Z"
          transform="translate(-721.144 -164.042)"
          fill="#1797df"
        />
      </g>
    </svg>
  </section>
</template>
